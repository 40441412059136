import { API_URL } from './settings'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import { translations } from './data'

let headers = new Headers()

headers.append('Accept', 'application/json')
headers.append('Content-Type', 'application/json')

headers.append('X-VATPAuthToken', sessionStorage.getItem('sessionToken'))

export function fetch_v2(path, method, body) {
  return fetch(API_URL + path, {
    headers: headers,
    method: method,
    body: body,
  })
}

export function encrypt_v1(string, key = 'SMBVATPORTALSECRETKEY') {
  const hashDigest = CryptoJS.AES.encrypt(string, key)
  return hashDigest.toString()
}

export function decrypt_v1(ciphertext) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, 'SMBVATPORTALSECRETKEY')
  return bytes.toString(CryptoJS.enc.Utf8)
}

export function saveDataOnLocalStorage(key, data) {
  try {
    // TODO encrypt token before store
    const serializedData = JSON.stringify(data)
    localStorage.setItem(key, serializedData)
  } catch (e) {
    // log error
  }
}

export function loadDataFromLocalStorage(key) {
  try {
    const serializedData = localStorage.getItem(key)
    return JSON.parse(serializedData)
  } catch (e) {
    return undefined
  }
}

/** Store data on localStorage
 *
 * @param key Key of the data
 * @param value Data for store
 */
export function setOnLocalStorage(key, value) {
  localStorage.setItem(key, value)
}

/** Get data from localStorage by the key
 *
 * @param key Key of the data
 *
 * @return Data from localstorage
 */
export function getFromLocalStorage(key) {
  return localStorage.getItem(key)
}

/** Customized axios instance for internal use
 *
 * @param method Request method
 * @param path Request path
 * @param data Request data
 * @param kwargs Additional options such as dataType
 *
 * @return Return axios instance
 */
export const internal_api = (
  method,
  path,
  data = {},
  kwargs = {},
  isJson = true,
  alert_errors = true,
) => {
  document.body.style.cursor = 'wait'

  let { dataType, pureRequest = false, requestParams = {} } = kwargs

  const ax = axios.create({
    baseURL: path.startsWith('http') ? null : API_URL,
  })

  // let temp_url = new URL(path.startsWith('http') ? path : `${API_URL}${path}`)
  // let temp_path = temp_url.searchParams.get('uri')
  // let parsed_path = temp_path.replace('/', '').split('/').join('_')

  if (dataType === 'json') {
    ax.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
  } else {
    ax.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  }
  // ax.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

  return ax({ method, url: path, data, ...requestParams })
    .then((response) => {
      document.body.style.cursor = 'default'

      if (!response) {
        window.alert('Empty response received')
        return null
      }

      if (pureRequest) return response

      if (!response.data) {
        window.alert('Empty response payload received')
        return {
          errNum: 500,
          errMsg: 'Empty response payload received',
          payload: {
            obj: {},
          },
        }
      }

      let { errNum, errMsg, payload } = response.data

      if (!errNum && !errMsg && !payload && isJson) {
        window.alert('Received response is not in JSON format')
        return { obj: {} }
      }

      if (!payload) {
        return response.data
      }

      if (errNum) {
        let notAuth = false
        if (
          payload.type &&
          payload.type === 'error' &&
          payload.details &&
          payload.details.nativeMessage &&
          payload.details.nativeMessage.toLowerCase().endsWith('not authorized')
        ) {
          notAuth = true
        }
        if ([30, 31].includes(errNum) || notAuth) {
          saveDataOnLocalStorage('sessionToken', '')
          saveDataOnLocalStorage('sessionExpire', '')
          window.location.reload()
        }

        if (errMsg && !notAuth) window.alert(errMsg)
        else if (!notAuth && alert_errors) window.alert('Something went wrong')

        // let sessionToken = response.config.headers['X-VATPAuthToken']
        // let contentType = response.config.headers['Content-Type']

        return { ...response.data, type: 'error' }
      }

      if (payload.type === 'error') {
        const { errNum, errMsg } = payload.obj
        if (errNum && errMsg && alert_errors) window.alert(`${errNum}: ${errMsg}`)
        else if (alert_errors) window.alert('Something went wrong. See details on console')
      }

      if (errNum !== 0) {
        payload = { ...payload, type: 'error', obj: { errNum, errMsg } }

        if (alert_errors) {
          window.alert(
            'Something went wrong. You can find details on console or on issue tracker system. Or contact with administrators.',
          )
        }
      }

      return payload
    })
    .catch((ex) => {
      document.body.style.cursor = 'default'

      window.alert('Something went wrong 2')
    })
}

/** Update token header (X-VATPAuthToken) on axios
 *
 * @param token Token string
 */
export const updateAuthToken = (token) => {
  axios.defaults.headers.common['X-VATPAuthToken'] = token
}

export const cleaningUpAfterLogout = (dispatch) => {
  //updateAuthToken('')
  delete axios.defaults.headers.common['X-VATPAuthToken']

  dispatch({ type: 'set', sessionToken: '' })
  dispatch({ type: 'set', sessionExpire: '' })
  dispatch({ type: 'set', firstName: '' })
  dispatch({ type: 'set', lastName: '' })
  dispatch({ type: 'set', companies: [] })
  dispatch({ type: 'set', company: {} })
  dispatch({ type: 'set', companyId: 0 })
  dispatch({ type: 'set', navs: [] })
  dispatch({ type: 'set', navsAllowed: [] })
}

export const isSessionActive = (sessionToken, sessionExpire) => {
  if (!sessionToken || !sessionExpire) {
    return false
  }

  const expireSeconds = moment(sessionExpire).diff(moment(), 'seconds')

  return expireSeconds > 0
}

export const getTranslation = (text, languageCode) => {
  let translation = translations[text]

  translation = translation ? translation[languageCode] : text

  return translation
}

export const isValidVariableName = (str) => {
  return /^\w*$/.test(str)
}

export const isValidCompanyName = (str) => {
  return /^[\S]*(\s[\S]*)*$/.test(str)
}

export const isValidVoen = (str) => {
  return /^\d{10}$/.test(str)
}

export const isValidPercent = (str) => {
  return /^(18)|\d$/.test(str)
}

export const isValidPhone = (str) => {
  return /^(994)?\d{9}$/.test(str)
}

export const isValidEmail = (str) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str)
}

export const generateRandomNumber = (min = 1, max = 1000) => {
  return Math.floor(Math.random(min, max) * 1000)
}

export function convertHourToCloseTimeUnit(durationInHour, durationPeriods) {
  const sortedDurationPeriods = [...durationPeriods].sort((a, b) => b.value - a.value)

  let duration = durationInHour
  let timeUnit = 'hour'

  for (let i = 0; i < sortedDurationPeriods.length; i++) {
    const durationPeriod = sortedDurationPeriods[i]

    if (durationInHour % durationPeriod.value !== 0) continue

    duration = durationInHour / durationPeriod.value
    timeUnit = durationPeriod.code

    break
  }

  return { duration, timeUnit }
}

export const deepCopyObject = (obj) => JSON.parse(JSON.stringify(obj))

export const buildSelectOptions = (val, text_az, text_en, text_ru, lang) => {
  let vs = val.split(',')
  let tas = text_az?.split(',') ?? []
  let tes = text_en?.split(',') ?? []
  let trs = text_ru?.split(',') ?? []

  let options = []
  for (var v in vs) {
    let t = vs[v]
    if (lang == 'az' && tas.length >= vs.length) {
      t = tas[v]
    } else if (lang == 'en' && tes.length >= vs.length) {
      t = tes[v]
    } else if (lang == 'ru' && trs.length >= vs.length) {
      t = trs[v]
    }
    options.push({ key: vs[v], text: t })
  }

  return options
}

/* Workflow Document START */

export function slugToName(slug) {
  slug = slug.split('_')
  slug = slug.map((s) => {
    const firstLetter = s.charAt(0).toUpperCase()
    const _s = s.substring(1)

    return firstLetter + _s
  })

  return slug.join(' ')
}

export function redefineInvoiceFields(parents, children) {
  const fields = []

  parents.forEach((p) => {
    fields.push({
      ...p,
      childFields: children.filter((cf) => cf.parent == p.id),
    })
  })

  return fields
}

export function redefineField(field, additionalFields, onlyParent) {
  const additionalField = additionalFields.find((f) => f.id === field.id)
  if (!additionalField || (additionalField.parent > 0 && onlyParent)) {
    return null
  }

  const childFields = []

  additionalFields.forEach((aF) => {
    if (aF.parent === field.id) {
      childFields.push({
        ...aF,
        id: aF.id,
        parent: field.id,
        name: aF.name,
        code: aF.code,
        no: 1,
        value: [],
      })
    }
  })

  return {
    ...additionalField,
    id: additionalField.id,
    parent: additionalField.parent,
    name: additionalField.name,
    code: additionalField.code,
    allowedRepetition: additionalField.allowedRepetition,
    no: 1,
    value: [],
    required: parseInt(field.required),
    childFields: childFields,
  }
}

export function redefineDocumentFields(document, documentType, additionalFields) {
  const documentFields = []

  /*documentType.fields = [
    { id: 13, required: '1', required_fields: [''], optional: '0' },
    { id: 15, required: '1', required_fields: [''], optional: '0' },
  ]*/
  documentType.fields.forEach((field) => {
    let newField = redefineField(field, additionalFields, true)
    if (newField) {
      documentFields.push(newField)
    }
  })

  /*document.fields = [
    { id: 13, cons: 1, value: '1' },
    { id: 15, cons: 1, value: '1' },
    { id: 19, cons: 1, value: '11' },
    { id: 19, cons: 2, value: '12' },
    { id: 19, cons: 3, value: '1' },
    { id: 27, cons: 1, value: '1' },
    { id: 27, cons: 2, value: '12' },
  ]*/
  if (document && document.fields) {
    const childFields = []

    document.fields.forEach((field) => {
      const { id, cons, value } = field

      if (cons === 1) {
        let found = false
        documentFields.every((f, fIdx) => {
          if (f.id === id) {
            documentFields[fIdx].value = value
            found = true
            return false
          }
          return true
        })
        if (!found) childFields.push(field)
      } else {
        const newField = documentFields.find((f) => f.id === id)
        if (newField) {
          const redefinedNewField = redefineField(newField, additionalFields, true)
          redefinedNewField.no = cons
          redefinedNewField.value = value

          documentFields.push(redefinedNewField)
        } else {
          childFields.push(field)
        }
      }
    })

    childFields.forEach((field) => {
      const { id, cons, value } = field

      const parent = additionalFields.find((f) => f.id === id).parent
      const redefinedNewField = redefineField(field, additionalFields, false)

      redefinedNewField.parent = parent
      redefinedNewField.parentNo = cons
      redefinedNewField.no = cons
      redefinedNewField.value = value

      const documentField = documentFields.find((f) => f.id === parent && f.no === cons)

      if (documentField) {
        documentField.childFields = documentField.childFields.map((f) =>
          f.id === id ? redefinedNewField : f,
        )
      }
    })
  }

  documentFields.sort((a, b) => a.code.localeCompare(b.code))

  return documentFields
}

export function prepareFieldsToSubmit(fields) {
  // const responseExample = {
  //   contract_number: '123ABC',
  //   main_desc: ['val1', 'val2'],
  //   account_type: [
  //     {
  //       account_type: 'checking',
  //       amount: '100',
  //       balance: '50',
  //     },
  //     {
  //       account_type: 'saving',
  //       amount: '1000',
  //       balance: '750',
  //     },
  //   ],
  // }

  const result = {}

  let isError = false

  if (!fields || fields.length == 0) {
    return { error: isError, fields: result }
  }

  fields.every((field) => {
    const { no, code, value, childFields, allowedRepetition, required } = field

    const idx = no - 1

    if (required && !value) {
      isError = true
      return false
    }

    if (no === 1 && allowedRepetition === 1) {
      // if siblings and child fields don't exist
      result[code] = value
    } else {
      // if child fields exist
      if (childFields && childFields.length) {
        if (!(code in result)) {
          result[code] = []
        }

        if (result[code][idx] === undefined) {
          result[code][idx] = {}
        }

        result[code][idx][code] = value

        childFields.forEach((childField) => {
          result[code][idx][childField.code] = childField.value
        })
      } else {
        // if child fields don't exist
        if (!(code in result)) {
          result[code] = []
        }

        result[code][idx] = value
      }
    }

    return true
  })

  if (result) {
    Object.keys(result).map((key) => {
      if (result[key] && Array.isArray(result[key])) {
        const newArr = []

        result[key].forEach((f) => newArr.push(f))

        result[key] = newArr
      }
    })
  }

  return { error: isError, fields: result }
}

export function redefineDocumentFieldsToPresent(documentFields, additionalFields) {
  const fields = []

  documentFields.forEach((documentField) => {
    const additionalField = additionalFields.find((f) => f.id === documentField.id)

    fields.push({ ...additionalField, cons: documentField.cons, value: documentField.value })
  })

  return fields
}

/* Workflow Document END */

/* Generic Data Manipulation */

export function formatDbDate(date, locale = 'az') {
  const dtParts = date.split(/ /)

  if (dtParts.length == 2) {
    const dParts = dtParts[0].split(/-/)
    return dParts[2] + '.' + dParts[1] + '.' + dParts[0] + ' ' + dtParts[1]
  }

  return date
}

export function formatAmount(amount) {
  let a = parseFloat(amount)
  return a
}

export function formatAddFieldValue(name, value) {
  if (name && name.indexOf('date') == -1 && name.indexOf('amount') == -1) {
    return value
  }

  if (name && name.indexOf('date') != -1) return formatDbDate(value)
  else if (name && name.indexOf('amount') != -1) return formatAmount(value)
}

function convertOldToNew(url) {
  let found = false
  if (url.indexOf('/admin/vat_in/etaxes_in_bol/') != -1) {
    url = url.replace('/admin/vat_in/etaxes_in_bol/print', '/etax/vat_in/list')
    found = true
  } else if (url.indexOf('/admin/vat_out/etaxes_bol/') != -1) {
    url = url.replace('/admin/vat_out/etaxes_bol/print', '/etax/vat_out/list')
    found = true
  }
  if (!found) {
    return url
  }
  let qs = url.split(/\?/)
  if (qs.length == 2) {
    let ids = qs[1]
      .split(/\&/)
      .map((p) => {
        let pair = p.split(/\=/)
        return pair[0].replace('item_', '')
      })
      .join(',')
    qs[1] = 'dl=print&ids=' + ids
    url = qs[0] + '?' + qs[1]
  }
  return url
}

export function openInNewTab(url) {
  var u = new URL(url)
  if (u.hostname.indexOf('.vatportal.az') != -1) {
    url = convertOldToNew(url)
    const path = url.startsWith('http://')
      ? url.replace('http://' + u.hostname, '')
      : url.replace('https://' + u.hostname, '')
    internal_api('get', path, {}, {}, false).then((payload) => {
      let printWindow = window.open('', '_blank')
      printWindow.document.write(payload)
      printWindow.document.close()
      printWindow.focus()
    })
  } else {
    window.open(url, '_blank', 'noreferrer')
  }
}

export function formatButtonTitle(title) {
  if (title.length < 12) {
    return title
  } else {
    return title.substring(0, 10) + ' ...'
  }
}

/* Generic Data Manipulation */
